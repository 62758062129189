import React from 'react';
import { graphql } from 'gatsby';

import HelpLayout from '../../../components/HelpLayout';
import HelpArticle from '../../../components/HelpArticle';
import SEO from '../../../components/SEO';

const HowToAddVideoInWixPage = ({ location, data }) => {
  return (
    <HelpLayout location={location}>
      <SEO
        title="How to add video in Wix"
        description="Learn how to add a video to your Wix page."
      />
      <HelpArticle
        title="How to add video in Wix"
        videoTitle="wix guide"
        videoSrc={data.site.siteMetadata.wixGuideVideoUrl}
      >
        <HelpArticle.StepContent>
          <p>
            In the <strong>Library</strong> select the video you want to embed
            in your webpage.
          </p>
        </HelpArticle.StepContent>
        <HelpArticle.StepContent>
          <p>
            Click on <strong>Actions</strong> and choose{' '}
            <strong>Get page embed code</strong>. Dialog will pop up and you
            press the <strong>Copy</strong> button. Now you have the embed code
            in your clipboard.
          </p>
        </HelpArticle.StepContent>
        <HelpArticle.StepContent>
          <p>Go to your Wix site’s editor.</p>
        </HelpArticle.StepContent>
        <HelpArticle.StepContent>
          <p>
            Click on <strong>Add</strong>, then choose the{' '}
            <strong>Embed</strong>, <strong>Custom Embeds</strong> and{' '}
            <strong>Embed a widget</strong>. The widget container will appear on
            your page.
          </p>
        </HelpArticle.StepContent>
        <HelpArticle.StepContent>
          <p>
            Click on <strong>Enter Code</strong> and paste the code in your
            clipboard. Confirm by clicking on <strong>Update</strong>. Then you
            can close the pop over.
          </p>
        </HelpArticle.StepContent>
        <HelpArticle.StepContent>
          <p>
            And that’s it! Now you can resize the video container as you like.
          </p>
        </HelpArticle.StepContent>
      </HelpArticle>
    </HelpLayout>
  );
};

export const query = graphql`
  query HowToAddVideoInWixPageQuery {
    site {
      siteMetadata {
        wixGuideVideoUrl
      }
    }
  }
`;

export default HowToAddVideoInWixPage;
